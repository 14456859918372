import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MoreIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import {
  FaBars,
  FaBlog,
  FaBuilding,
  FaBus,
  FaCarAlt,
  FaChartLine,
  FaChevronDown,
  FaChevronUp,
  FaCrown,
  FaDumbbell,
  FaGlassMartini,
  FaHardHat,
  FaHeart,
  FaHeartbeat,
  FaHome,
  FaIdCardAlt,
  FaInfoCircle,
  FaLaptop,
  FaMedal,
  FaMoneyBill,
  FaMotorcycle,
  FaPlane,
  FaSearch,
  FaShieldAlt,
  FaShip,
  FaSignOutAlt,
  FaSuitcaseRolling,
  FaSun,
  FaTractor,
  FaTree,
  FaUser,
  FaUserCircle,
  FaUsersCog,
} from "react-icons/fa";

import constants from "../../constants/index";
import {
  getCurrentUser,
  isAuthenticated,
  logout,
  removeCurrentUser,
} from "../../services/Auth";

import { useDispatch, useSelector } from "react-redux";

import { Link, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      display: "flex",
      alignItems: "flex-end",
      flexGrow: 1,
      textDecoration: "none",
    },
    appbar: {
      backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appbarLight: {
      backgroundColor: `${theme.palette.primary.contrastText}!important`,
    },
    appBarShift: {
      width: `calc(100% - ${constants.larguraMenuPainelAlunos}px)`,
      marginLeft: constants.larguraMenuPainelAlunos,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.contrastText,
    },
    linkMobile: {
      textDecoration: "none",
      color: theme.palette.primary.dark,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appBarJustified: {
      justifyContent: "space-between",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    customIcon: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(3),
    },
    alignMenu: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    hide: {
      display: "none",
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    home: {
      fontSize: 20,
    },
    menu: {
      color: theme.palette.primary.main,
    },
  })
);

const skills = [
  {
    name: "Residencial",
    icon: FaHome,
    url: constants.seguros.residencial,
  },
  {
    name: "Auto",
    icon: FaCarAlt,
    url: constants.seguros.auto,
  },
  {
    name: "Vida",
    icon: FaHeart,
    url: constants.seguros.vida,
  },
  {
    name: "Patrimônio",
    icon: FaMoneyBill,
    url: constants.seguros.patrimonio,
  },
  {
    name: "Embarcações",
    icon: FaShip,
    url: constants.seguros.embarcacoes,
  },
  {
    name: "Aeronaves",
    icon: FaPlane,
    url: constants.seguros.aeronaves,
  },
  {
    name: "Viagens",
    icon: FaSuitcaseRolling,
    url: constants.seguros.viagens,
  },
  {
    name: "R. Civil",
    icon: FaUser,
    url: constants.seguros.civil,
  },
  {
    name: "Saúde",
    icon: FaHeartbeat,
    url: constants.seguros.saude,
  },
  {
    name: "Garantia",
    icon: FaMedal,
    url: constants.seguros.garantia,
  },
  {
    name: "Engenharia",
    icon: FaHardHat,
    url: constants.seguros.engenharia,
  },
  {
    name: "Transportes",
    icon: FaBus,
    url: constants.seguros.transportes,
  },
  {
    name: "Eventos",
    icon: FaGlassMartini,
    url: constants.seguros.eventos,
  },
  {
    name: "Condomínio",
    icon: FaBuilding,
    url: constants.seguros.condominio,
  },
  {
    name: "Empresarial",
    icon: FaUsersCog,
    url: constants.seguros.empresarial,
  },
  {
    name: "Bikes e Motocicletas",
    icon: FaMotorcycle,
    url: constants.seguros.motocicletas,
  },
  {
    name: "Sistemas fotovoltaicos ",
    icon: FaSun,
    url: constants.seguros.energiaSolar,
  },
  {
    name: "Riscos cibernéticos",
    icon: FaLaptop,
    url: constants.seguros.cibernetico,
  },
  {
    name: "Rural",
    icon: FaTractor,
    url: constants.seguros.rural,
  },
  {
    name: "Riscos ambientais",
    icon: FaTree,
    url: constants.seguros.ambiental,
  },
];

function Navigation({ history }) {
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [studentsMenuOpen, setStudentsMenuOpen] = useState(false);
  const [anchorStudentOptions, setAnchorStudentOptions] = useState(null);
  const [currentEmail, setCurrentEmail] = useState("");
  const [insuranceAnchor, setInsuranceAnchor] = useState(null);
  const [consultationAnchor, setConsultationAnchor] = useState(null);
  const [insuranceMenuOpen, setInsuranceMenuOpen] = useState(false);
  const [consultationMenuOpen, setConsultationMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const open = useSelector((state) => state.openDrawerStudentsDashboard);
  const currentPage = useSelector((state) => state.currentStudentDashboardPage);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    getCurrentUser() && setCurrentEmail(getCurrentUser().email);
  }, [studentsMenuOpen]);

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleOpenStudentsMenu(event) {
    console.log("ok");
    setAnchorStudentOptions(event.currentTarget);
    setStudentsMenuOpen(true);
  }

  function handleCloseStudentsMenu() {
    setAnchorStudentOptions(null);
    setStudentsMenuOpen(false);
  }

  function handleOpenDrawer() {
    if (isAuthenticated()) {
      dispatch({ type: "OPEN_DRAWER_DASHBOARD", currentPage });
    }
  }

  function handleCloseDrawer() {
    dispatch({ type: "CLOSE_DRAWER_DASHBOARD" });
  }

  function handleChangePasswordPage() {
    dispatch({ type: "CURRENT_STUDENT_DASHBOARD_PAGE", page: 1 });
  }

  function handleLogout() {
    removeCurrentUser();
    logout();
    handleCloseDrawer();
    handleCloseStudentsMenu();
    history.push(constants.painel);
  }

  function handleChangeToAdminPage() {
    dispatch({ type: "CURRENT_STUDENT_DASHBOARD_PAGE", page: 0 });
    handleCloseStudentsMenu();
    history.push(constants.administracao);
  }

  function handleInsuranceMenuOpen(event) {
    setInsuranceAnchor(event.currentTarget);
    setInsuranceMenuOpen(true);
  }

  function handleConsultationMenuOpen(event) {
    setConsultationAnchor(event.currentTarget);
    setConsultationMenuOpen(true);
  }

  function handleInsuranceMenuClose() {
    setInsuranceAnchor(null);
    setInsuranceMenuOpen(false);
  }

  function handleConsultationMenuClose() {
    setConsultationAnchor(null);
    setConsultationMenuOpen(false);
  }

  const mobileMenuId = "primary-account-menu-mobile";
  const mobileStudentsMenuId = "students-menu-options";
  const insuranceMenuId = "insurance-menu";
  const consultationMenuId = "consultation-menu";

  const regularSections = (
    <>
      <div className={classes.sectionDesktop}>
        <Link to={constants.institucional} className={classes.link}>
          <Button color="inherit" aria-label="Institucional">
            <FaInfoCircle className={classes.home} />
            &nbsp;<p>Institucional</p>
          </Button>
        </Link>
        <Link to={constants.treinamento} className={classes.link}>
          <Button color="inherit" aria-label="Treinamento">
            <FaDumbbell className={classes.home} />
            &nbsp;<p>Treinamento</p>
          </Button>
        </Link>
        <Link to={constants.documentoscopia} className={classes.link}>
          <Button color="inherit" aria-label="Documentoscopia">
            <FaSearch className={classes.home} />
            &nbsp;<p>Documentoscopia</p>
          </Button>
        </Link>
        <Link to={constants.consultoria} className={classes.link}>
          <Button color="inherit" aria-label="Consultoria">
            <FaChartLine className={classes.home} />
            &nbsp;<p>Consultoria</p>
          </Button>
        </Link>
        <Link to={constants.blog} className={classes.link}>
          <Button color="inherit" aria-label="Blog">
            <FaBlog className={classes.home} />
            &nbsp;<p>Blog</p>
          </Button>
        </Link>
        <Button
          color="inherit"
          aria-label="Seguros"
          aria-controls={insuranceMenuId}
          onClick={handleInsuranceMenuOpen}
        >
          <FaShieldAlt className={classes.home} />
          &nbsp;
          <p>
            Seguros&nbsp;&nbsp;
            {insuranceAnchor ? <FaChevronUp /> : <FaChevronDown />}
          </p>
        </Button>
        <Link to={constants.painel} className={classes.link}>
          <Button color="inherit" aria-label="Área do Aluno">
            <FaUserCircle className={classes.home} />
            &nbsp;<p>Área do Aluno</p>
          </Button>
        </Link>
      </div>
      <div className={classes.sectionMobile}>
        <IconButton
          aria-label="area-do-aluno"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          open={isMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </div>
    </>
  );

  const sectionStudents = (
    <div>
      <IconButton
        color="inherit"
        aria-label="Opções Menu Alunos"
        aria-controls={mobileStudentsMenuId}
        aria-haspopup="true"
        onClick={handleOpenStudentsMenu}
      >
        <FaUserCircle className={classes.home} />
      </IconButton>
    </div>
  );

  const renderInsuranceMenu = (
    <Menu
      anchorEl={insuranceAnchor}
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      id={insuranceMenuId}
      open={insuranceMenuOpen}
      onClose={handleInsuranceMenuClose}
    >
      {skills &&
        skills.map((skill, index) => {
          const Icon = skill.icon;
          return (
            <Link
              key={index}
              className={clsx(classes.title, classes.menu)}
              onClick={handleInsuranceMenuClose}
              to={skill.url}
            >
              <MenuItem className={classes.alignMenu}>
                <Icon className={classes.home} />
                &nbsp;&nbsp;{skill.name}
              </MenuItem>
            </Link>
          );
        })}
    </Menu>
  );

  const renderStudentsMenu = (
    <Menu
      anchorEl={anchorStudentOptions}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      id={mobileStudentsMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={studentsMenuOpen}
      onClose={handleCloseStudentsMenu}
    >
      <MenuItem
        className={classes.alignMenu}
        onClick={handleChangePasswordPage}
      >
        {" "}
        <FaIdCardAlt className={clsx(classes.home, classes.customIcon)} />
        Alterar Senha
      </MenuItem>
      {currentEmail === constants.adminEmail && (
        <MenuItem
          className={classes.alignMenu}
          onClick={() => handleChangeToAdminPage()}
        >
          {" "}
          <FaCrown className={clsx(classes.home, classes.customIcon)} />
          Administração
        </MenuItem>
      )}
      <MenuItem className={classes.alignMenu} onClick={handleLogout}>
        <FaSignOutAlt className={clsx(classes.home, classes.customIcon)} />{" "}
        Deslogar
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link
        to={constants.institucional}
        className={classes.linkMobile}
        onClick={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="Institucional" color="inherit">
            <FaInfoCircle className={classes.home} />
          </IconButton>
          <p>Institucional</p>
        </MenuItem>
      </Link>
      <Link
        to={constants.treinamento}
        className={classes.linkMobile}
        onClick={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="Treinamento" color="inherit">
            <FaDumbbell />
          </IconButton>
          <p>Treinamento</p>
        </MenuItem>
      </Link>
      <Link
        to={constants.documentoscopia}
        className={classes.linkMobile}
        onClick={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="Documentoscopia" color="inherit">
            <FaSearch />
          </IconButton>
          <p>Documentoscopia</p>
        </MenuItem>
      </Link>
      <Link
        to={constants.consultoria}
        className={classes.linkMobile}
        onClick={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="Consultoria" color="inherit">
            <FaChartLine className={classes.home} />
          </IconButton>
          <p>Consultoria</p>
        </MenuItem>
      </Link>
      <Link
        to={constants.blog}
        className={classes.linkMobile}
        onClick={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="Blog" color="inherit">
            <FaBlog className={classes.home} />
          </IconButton>
          <p>Blog</p>
        </MenuItem>
      </Link>
      <Link
        to={constants.seguro}
        className={classes.linkMobile}
        onClick={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="Seguros" color="inherit">
            <FaShieldAlt className={classes.home} />
          </IconButton>
          <p>Seguros</p>
        </MenuItem>
      </Link>
      <Link
        to={constants.painel}
        className={classes.linkMobile}
        onClick={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="Área do Aluno" color="inherit">
            <FaUserCircle className={classes.home} />
          </IconButton>
          <p>Área do Aluno</p>
        </MenuItem>
      </Link>
    </Menu>
  );
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {history.location.pathname === constants.painel ||
          history.location.pathname === constants.administracao ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleOpenDrawer()}
              className={clsx({ [classes.hide]: open }, classes.menuButton)}
            >
              <FaBars />
            </IconButton>
          ) : null}
          <div className={classes.title}>
            <Link
              to={constants.inicio}
              className={clsx(classes.menuButton, classes.link, classes.home)}
            >
              <FaHome />
            </Link>
            <Typography variant="h6">JM Andrade</Typography>
          </div>
          {history.location.pathname === constants.painel ||
          history.location.pathname === constants.administracao
            ? sectionStudents
            : regularSections}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderStudentsMenu}
      {renderInsuranceMenu}
    </div>
  );
}

export default withRouter(Navigation);
