import React from "react";
import Helmet from "react-helmet";
import BestArticles from "../BestArticles/index";
import Blockquote from "../Blockquote/index";
import CallToAction from "../CallToAction/index";
import GenericCarousel from "../GenericCarousel/index";
import Hero from "../Hero/index";
import Quotation from "../Quotation/index";
import Skills from "../Skills/index";
import WhyChoose from "../WhyChoose/index";

import quote from "../../assets/images/training_quote.jpg";

import aig from "../../assets/images/seguradora_aig.png";
import alfa from "../../assets/images/seguradora_alfa.jpg";
import allianz from "../../assets/images/seguradora_allianz.jpg";
import argo from "../../assets/images/seguradora_argo.jpg";
import axa from "../../assets/images/seguradora_axa.png";
import azul from "../../assets/images/seguradora_azul.png";
import berkley from "../../assets/images/seguradora_berkley.png";
import bradesco from "../../assets/images/seguradora_bradesco.png";
import capemisa from "../../assets/images/seguradora_capemisa.png";
import chubb from "../../assets/images/seguradora_chubb.jpg";
import ezze from "../../assets/images/seguradora_esse.png";
import essor from "../../assets/images/seguradora_essor.png";
import excelsior from "../../assets/images/seguradora_excelsior.jpg";
import fairfax from "../../assets/images/seguradora_fairfax.png";
import fator from "../../assets/images/seguradora_fator.png";
import gboex from "../../assets/images/seguradora_gboex.jpg";
import generalli from "../../assets/images/seguradora_generalli.jpg";
import hdi from "../../assets/images/seguradora_hdi.png";
import icatu from "../../assets/images/seguradora_icatu.jpg";
import infinite from "../../assets/images/seguradora_infinite.png";
import itau from "../../assets/images/seguradora_itau.png";
import junto from "../../assets/images/seguradora_junto.png";
import mapfre from "../../assets/images/seguradora_mapfre.png";
import metlife from "../../assets/images/seguradora_metlife.png";
import mongeral from "../../assets/images/seguradora_mongeral.png";
import portoseguro from "../../assets/images/seguradora_portoseguro.png";
import pottencial from "../../assets/images/seguradora_pottencial.jpg";
import previsul from "../../assets/images/seguradora_previsul.png";
import prudential from "../../assets/images/seguradora_prudential.png";
import sancor from "../../assets/images/seguradora_sancor.jpg";
import sompo from "../../assets/images/seguradora_sompo.jpg";
import suhai from "../../assets/images/seguradora_suhai.jpg";
import sulamerica from "../../assets/images/seguradora_sulamerica.png";
import swiss from "../../assets/images/seguradora_swiss.png";
import tokio from "../../assets/images/seguradora_tokio.jpg";
import unimedSeguros from "../../assets/images/seguradora_unimed.jpg";
import vumi from "../../assets/images/seguradora_vumi.png";
import yelum from "../../assets/images/seguradora_yelum.svg";
import zurich from "../../assets/images/seguradora_zurich.png";

import crsnsp from "../../assets/images/crsnsp.jpeg";
import aida from "../../assets/images/logo_aida.png";
import cnseg from "../../assets/images/logo_cnseg.png";
import cqcs from "../../assets/images/logo_cqcs.png";
import ens from "../../assets/images/logo_ens.png";
import esa from "../../assets/images/logo_esa.png";
import fenacor from "../../assets/images/logo_fenacor.png";
import fenseg from "../../assets/images/logo_fenseg.png";
import ibracor from "../../assets/images/logo_ibracor.jpeg";
import ipog from "../../assets/images/logo_ipog.png";
import mf from "../../assets/images/logo_mf.jpg";
import oabgo from "../../assets/images/logo_oabgo.png";
import seguroComCorretor from "../../assets/images/logo_seguro_com_corretor.jpeg";
import sfn from "../../assets/images/logo_sfn.png";
import sicoob from "../../assets/images/logo_sicoob.png";
import sincorgo from "../../assets/images/logo_sincorgo.png";
import susep from "../../assets/images/logo_susep.png";
import tss from "../../assets/images/logo_tss.png";

import img from "../../assets/images/law.jpg";

const partners = [
  {
    name: "Allianz",
    img: allianz,
    url: "https://www.allianz.com.br",
  },
  {
    name: "Azul",
    img: azul,
    url: "https://www.azulseguros.com.br",
  },
  {
    name: "HDI",
    img: hdi,
    url: "https://www.hdiseguros.com.br/pt",
  },
  {
    name: "Itau",
    img: itau,
    url: "https://www.itau.com.br/seguros/",
  },
  {
    name: "Porto Seguro",
    img: portoseguro,
    url: "http://www.portoseguro.com.br",
  },
  {
    name: "Mapfre",
    img: mapfre,
    url: "https://www.mapfre.com.br/seguro-br/para-voce/",
  },
  {
    name: "Sulamérica",
    img: sulamerica,
    url: "https://portal.sulamericaseguros.com.br/",
  },
  {
    name: "Generalli",
    img: generalli,
    url: "https://www.generali.com.br",
  },
  {
    name: "Metlife",
    img: metlife,
    url: "https://compre.metlife.com.br",
  },
  {
    name: "Zurich",
    img: zurich,
    url: "https://www.zurich.com.br",
  },
  {
    name: "MongeralAegon",
    img: mongeral,
    url: "https://www.mongeralaegon.com.br",
  },
  {
    name: "Icatu Seguros",
    img: icatu,
    url: "https://portal.icatuseguros.com.br",
  },
  {
    name: "Alfa Seguradora",
    img: alfa,
    url: "https://wwws.alfaseguradora.com.br/portal/",
  },
  {
    name: "Tokio Marine",
    img: tokio,
    url: "https://www.tokiomarine.com.br",
  },
  {
    name: "Capemisa",
    img: capemisa,
    url: "http://www.capemisa.com.br",
  },
  {
    name: "Gboex",
    img: gboex,
    url: "https://www.gboex.com.br",
  },
  {
    name: "Previsul",
    img: previsul,
    url: "https://www.previsul.com.br",
  },
  {
    name: "Unimed",
    img: unimedSeguros,
    url: "http://www2.segurosunimed.com.br/ecommerce/",
  },
  {
    name: "Suhai",
    img: suhai,
    url: "https://suhaiseguradora.com",
  },
  {
    name: "Fator",
    img: fator,
    url: "https://portal.fatorseguradora.com.br",
  },
  {
    name: "Chubb",
    img: chubb,
    url: "https://www.chubb.com",
  },
  {
    name: "Berkley",
    img: berkley,
    url: "https://www.berkley.com.br",
  },
  {
    name: "axa",
    img: axa,
    url: "https://www.axa.com.br",
  },
  {
    name: "aig",
    img: aig,
    url: "https://www.aig.com.br",
  },
  {
    name: "Sompo",
    img: sompo,
    url: "https://sompo.com.br",
  },
  {
    name: "Excelsior",
    img: excelsior,
    url: "https://www.excelsiorseguros.com.br",
  },
  {
    name: "Sancor",
    img: sancor,
    url: "https://www.sancorseguros.com.br",
  },
  {
    name: "FairFax",
    img: fairfax,
    url: "http://www.fairfax.com.br",
  },
  {
    name: "Pottencial",
    img: pottencial,
    url: "https://www.pottencial.com.br",
  },
  {
    name: "Junto",
    img: junto,
    url: "https://www.juntoseguros.com",
  },
  {
    name: "Argo",
    img: argo,
    url: "https://www.argoseguros.com.br/quem-somos/",
  },
  {
    name: "Ezze",
    img: ezze,
    url: "https://www.ezzeseguros.com.br/",
  },
  {
    name: "Prudential",
    img: prudential,
    url: "https://www.prudentialdobrasil.com.br/",
  },
  {
    name: "Vumi",
    img: vumi,
    url: "https://www.vumichoice.com/",
  },
  {
    name: "Essor",
    img: essor,
    url: "https://www.essor.com.br/",
  },
  {
    name: "Bradesco Seguros",
    img: bradesco,
    url: "https://www.bradescoseguros.com.br/clientes",
  },
  {
    name: "Swiss Re Corporation Solutions Brasil",
    img: swiss,
    url: "https://corporatesolutions.swissre.com/brasil-seguros/",
  },
  {
    name: "Yelum Seguros",
    img: yelum,
    url: "https://www.yelumseguros.com.br/",
  },
  {
    name: "Infinite Seguradora",
    img: infinite,
    url: "https://infiniteseguradora.com.br/",
  },
];

// partners = partners.sort((a, b) => {
//   if (a.name < b.name) {
//     return -1
//   }
//   if (a.neme > b.name) {
//     return 1
//   }
//   return 0
// })

const links = [
  {
    name: "Seguro com corretor",
    img: seguroComCorretor,
    url: "https://www.segurocomcorretor.com.br/visualizar-corretor/junio-marcos-andrade-de-sousa-matos/908",
  },
  {
    name: "Susep",
    img: susep,
    url: "http://www.susep.gov.br",
  },
  {
    name: "Ibracor",
    img: ibracor,
    url: "http://www.ibracor.org.br/",
  },
  {
    name: "Fenacor",
    img: fenacor,
    url: "https://www.fenacor.org.br",
  },
  {
    name: "Sincor-GO",
    img: sincorgo,
    url: "http://sincorgo.com.br",
  },
  {
    name: "Escola Nacional de Seguros",
    img: ens,
    url: "http://www.ens.edu.br",
  },
  {
    name: "CNSEg",
    img: cnseg,
    url: "http://cnseg.org.br",
  },
  {
    name: "FenSEg",
    img: fenseg,
    url: "http://fenseg.org.br",
  },
  {
    name: "CQCS",
    img: cqcs,
    url: "https://www.cqcs.com.br",
  },
  {
    name: "Sicoob Seguros",
    img: sicoob,
    url: "https://sicoobcredseguro.com.br ",
  },
  {
    name: "Tudo Sobre Seguros",
    img: tss,
    url: "https://www.tudosobreseguros.org.br",
  },
  {
    name: "Aida",
    img: aida,
    url: "https://aida.org.br",
  },
  {
    name: "IPOG",
    img: ipog,
    url: "https://ipog.edu.br",
  },
  {
    name: "OAB Goiás",
    img: oabgo,
    url: "http://www.oabgo.org.br/oab/home/",
  },
  {
    name: "Escola Nacional",
    img: esa,
    url: "http://www.oabgo.org.br/esa/home/",
  },
  {
    name: "Sistema Financeiro Nacional",
    img: sfn,
    url: "https://www.bcb.gov.br/estabilidadefinanceira/sfn",
  },
  {
    name: "Ministério da Economia disponibiliza dados do Sistema Nacional de Emprego",
    img: mf,
    url: "http://www.mdic.gov.br/index.php/component/content/article/61-noticias/3848-ministerio-da-economia-disponibiliza-dados-do-sistema-nacional-de-emprego",
  },
  {
    name: "Conselho de Recursos do Sistema Nacional de Seguros Privados, de Previdência Aberta e de Capitalização - CRSNSP",
    img: crsnsp,
    url: "https://www.gov.br/fazenda/pt-br/orgaos/colegiados/crsnsp",
  },
];

export default function Home() {
  return (
    <>
      <Helmet>
        <title>JM Andrade</title>
        <meta
          name="description"
          content="JM Andrade, além de corretora de seguros é uma empresa especializada em Consultoria, Acessoria e Treinamento para profissionais para área de seguros."
        />
      </Helmet>
      <Hero />
      <WhyChoose />
      <Skills />
      <GenericCarousel
        data={partners}
        title="Trabalhamos com as principais seguradoras"
      />
      <BestArticles />
      <Blockquote
        quote="O conhecimento é uma riqueza imensurável e uma fonte inesgotável, para aqueles que estão dispostos a aprender. Faça a diferença em sua vida"
        author="Júnio Marcos Águia"
        img={quote}
      />
      <Quotation />
      <CallToAction
        img={img}
        title="Você conhece a legislação de seguros ?"
        buttonTitle="Acessar"
      />
      <GenericCarousel highlightFirst data={links} title="Links Úteis" />
    </>
  );
}
